import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@common-src/model/form-control';

export class PropertyTypeEntityModel extends BaseEntityModel {
    static baseUrl = `${COMMON_BASE_REQUEST_PATH}/propertyType`;
    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        required: true,
        readonly: true
    } as FormControlTextModel)
    code: string = undefined;

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    remark: string = undefined;
}
