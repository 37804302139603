



















































import { Component, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { PropertyEntityType, ViewModeType } from '@common-src/model/enum';
import CommonService from '@common-src/service/common';
import PropertyTypeService from '@common-src/service3/property-type';
import { PropertyTypeEntityModel } from '@common-src/entity-model/property-type-entity';
import { UserStoreModule } from '@common-src/store/modules/user';

const propertyTypeService = new PropertyTypeService();

@Component
export default class ConfigIndexComponent extends BaseComponent {
    PropertyEntityType = PropertyEntityType;
    allPropertyTypes: Array<any> = null;

    created() {
        this.init();
    }
    /**
     * 是否是超级管理员
     */
    get IsSuperAdmin(): boolean {
        return _.get(UserStoreModule.UserInfo, 'IsSuperAdmin');
    }
    addPropertyType() {
        (this.$refs.formDialog as any).dialogOpen(new PropertyTypeEntityModel(), propertyTypeService, ViewModeType.NEW);
    }

    updateClick(item) {
        const propertyTypeModel = new PropertyTypeEntityModel();
        propertyTypeModel.id = item.id;
        propertyTypeModel.code = item.propertyType;
        propertyTypeModel.remark = item.name;
        (this.$refs.formDialog as any).dialogOpen(propertyTypeModel, propertyTypeService, ViewModeType.UPDATE);
    }

    deleteClick(item) {
        propertyTypeService.delete(item).then(() => {
            this.init();
        });
    }

    formDialogOK() {
        this.init();
    }

    init() {
        CommonService.getAllPropertyTypes().then(res => {
            this.allPropertyTypes = res;
            if (this.allPropertyTypes && this.allPropertyTypes.length > 0) {
                this.$router.push(`/dashboard/config/property/${_.get(this.allPropertyTypes, '[0].propertyType')}`);
            }
        });
    }
    @Watch('$route.path', { immediate: false })
    routerChange(newVal: string): void {
        if (newVal === '/dashboard/config') {
            this.init();
        }
    }
    get SelectedKeys(): string {
        if (this.$route.name === 'property') {
            return this.$route.params.type;
        }
        return this.$route.name || '';
    }
}

